<template>
  <div class="faq-container">
    <p class="title">
      ANY QUESTIONS?
    </p>
    <div class="accordion-group">
      <Accordion v-for="(item, index) in accordionItems" :key="index" :contact="true" is-blue-arrow="true">
        <template v-slot:title>
          <h4 class="title">{{ item.question }}</h4>
        </template>
        <template v-slot:content>
          <p v-html="$options.filters.nl2br(item.answer)"></p>
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion.vue";

export default {
  name: "faqComponent",
  components: {Accordion},
  props: ['accordionItems'],
  data() {
    return {
      // accordionItems: [
      //   { title: "Delivery", content: "Your assigned Case Manager will arrange an introduction meeting in the next 10 working days to understand your goals, assess your needs and discuss the support that can be provided." },
      //   { title: "Who can join this training?", content: "Your assigned Case Manager will arrange an introduction meeting in the next 10 working days to understand your goals, assess your needs and discuss the support that can be provided." },
      //   { title: "What are Healing Classrooms?", content: "Your assigned Case Manager will arrange an introduction meeting in the next 10 working days to understand your goals, assess your needs and discuss the support that can be provided." },
      //   { title: "Testimonials", content: "Your assigned Case Manager will arrange an introduction meeting in the next 10 working days to understand your goals, assess your needs and discuss the support that can be provided." }
      // ]
    };
  },
  filters: {
    nl2br: function(value) {
      if (!value) return '';
      return value.replace(/\n/g, '<br>');
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-container{
  .title{
    color: #024694;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 0.72px;
    margin: 0 0 1rem 0;
  }
}
.accordion-group{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .title{
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    margin: 0;
  }
  p{
    color: #262532;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
  }
}
</style>
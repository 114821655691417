<template>
  <div :class="{ show: showPopover,}"
       :style="{ top: mouseCoordinates.y + 'px', left: positionX }"
       class="popover" @mouseenter="hidePopover()">
    <!--    <img :class="{'left': mouseInLeftPart, 'right': mouseInRightPart}" :alt="$t('corner')" class="corner" src="@/assets/images/purple-corner.png">-->
    <div class="content">
      <h4>{{ title }}</h4>
      <ul :class="[{'right-side-markers': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
        <li
            v-for="item in opportunities">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardPopover',
  props: {
    title: null,
    opportunities: null,
    showPopover: Boolean,
    mouseInLeftPart: Boolean,
    mouseInRightPart: Boolean,
    mouseCoordinates: Object, // Новый пропс для координат мыши
    selectedLanguage: String
  },
  data() {
    return {
      positionYClass: null,
      positionXClass: null
    }
  },
  methods: {
    hidePopover() {
      this.$emit('hidePopover');
    }
  },
  computed: {
    positionX() {
      // Возвращаем разные значения в зависимости от положения мыши
      if (this.mouseInLeftPart) {
        return this.mouseCoordinates.x + 'px';
      } else if (this.mouseInRightPart) {
        return this.mouseCoordinates.x - 315 + 'px';
      }
      return this.mouseCoordinates.x + 'px'; // Если не находится ни в одной из частей
    },
  },
}
</script>

<style lang="scss" scoped>


ul.right-side-markers {
  list-style: none; /* Убираем стандартные маркеры */
  padding: 0;
}

ul.right-side-markers li {
  position: relative; /* Для позиционирования псевдоэлемента */
  padding-right: 20px; /* Отступ справа для маркера */
}

ul.right-side-markers li::after {
  content: '•'; /* Символ маркера */
  position: absolute;
  right: 0; /* Позиционирование справа */
  top: 0;
}

.popover {
  width: 315px;
  min-height: 150px;
  padding: 32px;
  position: absolute;
  background-color: $main-color;
  z-index: 500;
  border-radius: 32px;
  //display: none;
  //opacity: 0;
  //transition: opacity 0.2s ease-in-out;


  .corner {
    position: absolute;
    top: 0;
    //right: 0;
    transform: translate(-25px, 0);
  }

  .content {
    width: 230px;
    height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: calc(var(--main-font-size) - 2px);
      font-weight: bold;
      margin: 0 auto 12px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.show {
  display: flex !important;
}

.bottom {

  img {
    bottom: 0;
    top: unset !important;
    transform: rotate(90deg);
  }
}

.top {
  img {
    top: 0;
    bottom: unset !important;
  }
}

.right {
  right: 0;
  transform: translate(0, 0) !important;
  //img {
  //transform: translate(0, 0);
  //}
}

.left {

  //left: 0;
  //.corner {
  transform: rotate(-90deg) !important;

}

.left.bottom {
  justify-content: flex-end;

  img {
    transform: rotate(180deg);
  }
}

</style>
<template>
  <div>
    <div class="banner" :style="getBanner()">
      <div class="container">
        <div class="banner-content">
          <h1 class="text-default w-30">{{ $t('allYouNeedToKnow') }}</h1>
          <h1 class="text-bold-700">{{ $t('aboutRep') }}</h1>
        </div>
      </div>
      <img src="@/assets/icons/slice-top.png" alt="" class="corner">
    </div>

    <div class="content">
      <div class="container">
        <div class="wrapper">
          <a class="logo" @click="redirect()">
            <img :alt="$t('wj')" :src="logoUrl">
          </a>

          <div class="accordions">
            <Accordion is-blue-arrow="true" :opened="true"
                       @opened="changeLogo(0)"
            >
              <template v-slot:title>
                <h2 class="main-color">{{ $t('theRefugeeEmployability') }}</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>
                    {{ $t('theRefugeeEmployabilityProgrammeRepIsAUkGovernment') }}
                    {{ $t('theRepWillComplementExistingSupportDeliveredAcross') }}
                  </p>

                  <p class="ac-content-link" @click="showHowItWorks">{{ $t('howItWorks') }}</p>
                  <p class="ac-content-link-1" @click="showAccessibilityModal">{{ $t('accessibleSupport') }}</p>
                </div>
              </template>
            </Accordion>

            <Accordion is-blue-arrow="true"
                       @opened="changeLogo(1)"
            >
              <template v-slot:title>
                <h2 class="main-color">{{ $t('worldJewishRelief') }}</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>
                    {{ $t('worldJewishReliefWasFoundedIn1933ToRescueJewishPeo') }}
                  </p>
                  <p>
                    {{ $t('worldJewishReliefLaunchedTheSpecialistTrainingAndE') }}

                  </p>
                </div>
              </template>
            </Accordion>

            <Accordion is-blue-arrow="true"
                       @opened="changeLogo(2)"
            >
              <template v-slot:title>
                <h2 class="main-color">{{ $t('tern') }}</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>{{ $t('ternIsASocialEnterpriseAndNonprofitFoundedIn2016Th') }}</p>
                  <p>{{ $t('ternRunOnlineEntrepreneurshipProgrammesAndServices') }}</p>
                  <p>{{ $t('ternAlsoWorksWithLocalPartnerOrganisationsInTheSou') }}</p>
                </div>
              </template>
            </Accordion>

            <Accordion is-blue-arrow="true"
                       @opened="changeLogo(3)"
            >
              <template v-slot:title>
                <h2 class="main-color">{{ $t('theInternationalRescueCommitteeIrc') }}</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>
                    {{ $t('theInternationalRescueCommitteeIrcHelpsPeopleAffec') }}
                  </p>
                  <p>
                    {{ $t('theIrcLaunchedItsFirstProgrammeInTheUkIn2021TheIrc') }}
                  </p>
                </div>
              </template>
            </Accordion>
          </div>
        </div>
      </div>
    </div>

    <Modal
        :is-modal-visible="showHowItWorksModal"
        @close="closeHowItWorks"
    >
      <template v-slot:modal>
        <div class="modal-content">
          <p>
            {{ $t('theProgrammeIsDesignedThroughACaseworkingModelYouW') }}</p>
          <p>{{ $t('yourCaseManagerWillReviewYourProgressOnARegularBas') }}</p>
        </div>
      </template>
    </Modal>

    <Modal
        :is-modal-visible="showAccessibleSupportModal"
        @close="closeAccessibilityModal"
    >
      <template v-slot:modal>
        <div class="modal-content">
          <p>{{ $t('youCanBeSupportedByTheProgrammeForUpTo18MonthsWith') }}</p>

          <p :class="[{'direction': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">{{ $t('1EmployabilitySupportIncludingSelfemploymentAndEnt') }}</p>

          <p :class="[{'direction': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">{{ $t('2IntegrationSupportHelpingYouIntegrateAndSettleInt') }}</p>

          <p :class="[{'direction': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">{{ $t('3EnglishLanguageLearningProvidingFormalAndInformal') }}</p>

          <p>{{ $t('yourParticipationInTheProgrammeIsVoluntaryAndVouCa') }}</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";

export default {
  name: 'AboutUsPage',
  components: {Modal, Accordion},
  props: ['selectedLanguage'],
  data() {
    return {
      banner: {},
      showHowItWorksModal: false,
      showAccessibleSupportModal: false,
      redirectUrl: '',
      logoUrl: require('@/assets/images/REP_Logo_simplified.png'),
      logos: [
        require('@/assets/images/REP_Logo_simplified.png'),
        require('@/assets/images/World-Jewish-Relief-Stacked-NEW+.png'),
        require('@/assets/images/TERN_Logo_Round.png'),
        require('@/assets/images/irc_arrow_square_rgb.png')
      ],
      redirectUrls: [
          '',
          'https://www.worldjewishrelief.org/',
          'https://wearetern.org/',
          'https://www.rescue.org/uk'
      ]
    }
  },
  methods: {
    showHowItWorks() {
      this.showHowItWorksModal = true;
    },
    closeHowItWorks() {
      this.showHowItWorksModal = false;
      // console.log(this.showHowItWorksModal)
    },
    showAccessibilityModal() {
      this.showAccessibleSupportModal = true;
    },
    closeAccessibilityModal() {
      this.showAccessibleSupportModal = false;
    },
    changeLogo(index) {
      this.logoUrl = this.logos[index];
      this.redirectUrl = this.redirectUrls[index];
    },
    redirect() {
      if (this.redirectUrl.length) {
        window.open(this.redirectUrl, '_blank')
      }
    },
    getBanner() {
      if (this.banner.url) {
        return {
          backgroundImage: 'url(' +process.env.VUE_APP_API_URL+ this.banner.url + ')'
        }
      }
      else {
        return {}
      }
    },
    async initBanner(){
      await axios.get(process.env.VUE_APP_API_URL + "/images/about-us").then((response) => {
        this.banner = response.data;
      });
    },
  },
  async mounted() {
    await this.initBanner();
  }
}
</script>

<style lang="scss" scoped>

.direction{
  direction: rtl;
  text-align: start;
}

.banner {
  //background-image: url("@/assets/images/about-us-banner.png");
  height: 482px;
  position: relative;

  .corner {
    position: absolute;
    top: -2px;
    width: 128px;
    @media (max-width: 1224px) {
      width: 70px;
      right: 0;
      transform: rotate(90deg);
    }
  }
  @media (max-width: 1224px) {
    background-image: none!important;
    background: rgba(222, 225, 218, 1);
    height: 200px;
  }
}

.w-30{
  width: 40%;
  @media (max-width: 1400px) {
    width: 30%;
  }
  @media (max-width: 1224px) {
    width: 100%;
  }
}

.banner-content {
  @media (max-width: 1224px) {
    padding: 0 16px;
    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }
}

.content {
  background-color: rgba(146, 197, 235, 1);
  display: flex;
  align-items: center;
  min-height: 45vh
}

.wrapper {
  display: flex;
  gap: 24px;
  padding: 200px 0;

  .logo {
    width: 389px;
    height: 378px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: center;
    padding: 32px 16px 120px 16px;
    .logo {
      width: 328px;
      height: 318px;
    }
  }


}

.main-color {
  color: $main-color;
  width: 100%;
}

.accordions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  h2 {
    font-weight: 600;
  }

  @media (max-width: 1224px) {
    h2 {
      font-size: calc(var(--main-font-size) + 4px);
      line-height: 25px;
      //max-width: 380px;
    }
  }

  .accordion {
    background-color: white;

    ::v-deep .body {
      border-color: white;
    }
  }

  .ac-content {
    display: flex;
    flex-direction: column;
    color: black;

    p {
      margin-bottom: 20px;
      font-size: calc(var(--main-font-size) + 2px);
      line-height: 27px;
      &.ac-content-link-1 {
        cursor: pointer;
        margin-bottom: 10px;
        color: #024694;
        text-decoration: underline;
      }
      &.ac-content-link {
        cursor: pointer;
        margin-bottom: 10px;
        color: #024694;
        text-decoration: underline;

      }
    }

    a {
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
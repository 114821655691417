<template>
  <label class="switch">
    <input type="checkbox" @change="changeChecked($event.target.checked)">
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  name: 'Slider',
  methods: {
    changeChecked(value) {
      this.$emit('checkedChange', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  background-color: #ccc;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    border-radius: 3px;
    background-color: white;
    transition: .4s;
  }
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
</style>
<template>
  <div v-if="showBreadcrumbs" class="breadcrumbs" :class="{'no-banner': !haveBanner}">
      <div class="breadcrumbs-wrapper">
        <div class="item">
          <router-link to="/"><img :alt="$t('home2')" src="@/assets/icons/home.svg"></router-link>
          <p>/</p>
        </div>

        <div class="item">
          {{ title }}
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  data() {
    return {
      showBreadcrumbs: false,
      title: '',
      haveBanner: false
    }
  },

  mounted() {
    const breadCrumb = this.$route.meta.breadcrumb;
    this.haveBanner = breadCrumb.haveBanner;
    if (breadCrumb.title.length) {
      this.title = breadCrumb.title;
      this.showBreadcrumbs = true;
    } else {
      this.showBreadcrumbs = false;
    }
  },

  watch: {
    $route() {
      const breadCrumb = this.$route.meta.breadcrumb;
      this.haveBanner = breadCrumb.haveBanner;
      // console.log(this.haveBanner)
      if (breadCrumb.title.length) {
        this.title = breadCrumb.title;
        this.showBreadcrumbs = true;
      } else {
        this.showBreadcrumbs = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.breadcrumbs {
  width: auto;
  position: absolute;
  height: 22px;
  bottom: -46px;
  z-index: 1;

  @media (max-width: 1224px) {
    bottom: -38px;
    left: 1rem;
  }
}

.no-banner {
  left: unset!important;
}

.breadcrumbs-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 100%;

  .item {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 5px;
    font-size: calc(var(--main-font-size) - 2px);
  }
}

</style>
<template>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div v-for="leaflet in leaflets" :key="leaflet.id" class="item">
          <div class="document">
            <img src="@/assets/icons/document.svg" :alt="$t('document')">
            <div class="text">
              <p>{{ leaflet.title }}</p>
            </div>
          </div>
          <a href="" class="btn-secondary" @click.prevent="showPoster(leaflet.url)">
            <img :alt="$t('download')" src="@/assets/icons/download.svg">
            {{ $t('downloadHere') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['leaflets'],
  methods: {
    showPoster(url) {
      window.open(process.env.VUE_APP_API_URL + url, '_blank').focus();
    }
  }
}
</script>

<style scoped lang="scss">
.banner {
  background-image: url("@/assets/images/leaflets-banner.png");
  height: 482px;
  position: relative;
  text-align: start;

  .corner {
    position: absolute;
    top: 0;
    width: 128px;
    @media (max-width: 1224px) {
      width: 70px;
      right: 0;
      transform: rotate(90deg) translate(-13px, 13px);
    }
  }

  @media (max-width: 1224px) {
    background: rgba(169, 219, 210, 1);
    height: 200px;
  }
}

.banner-content {
  @media (max-width: 1224px) {
    padding: 0 16px;
    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }
}

.content {
  display: flex;
  align-items: center;
}

.wrapper {
  display: flex;
  gap: 32px;
  //padding: 150px 0 20vh;
  flex-direction: column;
  @media (max-width: 1224px) {
    padding: 51px 16px 120px 16px;
    gap: 64px;
  }
}

.item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1224px) {
    flex-direction: column;
    gap: 24px;
  }

  .document {
    display: flex;
    gap: 16px;
    width: 70%;

    @media (max-width: 1225px) {
      width: 100%;
    }

    .text {
      font-size: calc(var(--main-font-size) + 2px);
      font-weight: 700;
      color: $main-color;
      margin: auto 0;
      height: fit-content;
    }
  }

  .btn-secondary {
    width: 288px;
    font-size: calc(var(--main-font-size) + 2px);
    font-weight: 700;
    padding: 16px 32px;
    gap: 10px;
    line-height: 16px;
    border-color: black;
    color: black;
    @media (max-width: 1224px) {
      width: 100%;
    }
    &:hover{
      border-color: #034694;
      background-color: #034694;
      color: #FFFFFF;
      img{
        filter: brightness(0) invert(1);
      }
    }
  }
}
</style>
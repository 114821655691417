<template>
  <button>{{label}}</button>
</template>

<script>
export default {
  name: "btnMain",
  props:['label'],
}
</script>


<style scoped lang="scss">
button{
  border-radius: 8px;
  background: #184892;
  border: none;
  outline: none;
  padding: 0.5rem 2rem;
  color: #FFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.096px;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import NewsPage from "@/views/NewsPage.vue";
import AboutUsPage from "@/views/AboutUsPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import NewsReadMore from "@/views/NewsReadMore.vue";
import LeafletsPage from "@/views/LeafletsPage.vue";
import ReferSomeonePage from "@/views/ReferSomeonePage.vue";
import ArticlePage from "@/views/ArticlePage.vue";
import Events from "@/views/Events.vue";

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      breadcrumb: {
        title: '',
      }
    }
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage,
    meta: {
      breadcrumb: {
        title: 'News',
        haveBanner: false
      }
    }
  },
  {
    path: '/read-more/:url',
    name: 'newsReadMore',
    component: NewsReadMore,
    props: true,
    meta: {
      breadcrumb: {
        title: 'News article',
        haveBanner: false
      }
    }
  },
  {
    path: '/page/:url',
    name: 'ArticlePage',
    component: ArticlePage,
    props: true,
    meta: {
      breadcrumb: {
        title: 'Page',
        haveBanner: false
      }
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: {
      breadcrumb: {
        title: 'Contact us',
        haveBanner: true
      }
    }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsPage,
    meta: {
      breadcrumb: {
        title: 'About us',
        haveBanner: true
      }
    }
  },
  {
    path: '/leaflets',
    name: 'leaflets',
    component: LeafletsPage,
    meta: {
      breadcrumb: {
        title: 'Leaflets',
        haveBanner: true
      }
    }
  },
  {
    path: '/refer-someone',
    name: 'refer-someone',
    component: ReferSomeonePage,
    meta: {
      breadcrumb: {
        title: 'Refer someone',
        haveBanner: false
      }
    }
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      breadcrumb: {
        title: 'Events',
        haveBanner: false
      }
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

export default router;

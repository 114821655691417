<template>
  <div v-if="showAccessibilityTools" id="ac-tools" ref="ac-tools" class="accessibility-tools" :class="{oversize: isOversizeWidget}">
    <div class="title">
      <span><span class="rep">{{ $t('rep') }} &nbsp;</span> {{ $t('accessibilityTool') }}</span>
      <img :alt="$t('close')" src="@/assets/icons/close.svg" @click="closeTools()">
    </div>

    <div class="activation">
      <div class="desc">
        {{ $t('accessibilityMenu') }}
        <div class="combination">{{ $t('ctrlU') }}</div>
      </div>

      <div class="desc">
        {{ $t('oversizedWidget') }}

        <Slider @checkedChange="toggleOversizeWidget($event)"/>
      </div>

      <div class="tools">
        <div class="tool" @click="changeContrast()">
          <div class="tool-title">
            <img :alt="$t('contrast')" src="@/assets/icons/contrast.svg">
            {{ $t('contrast4', {expr: contrastStep + 1}) }}
          </div>
          <div ref="contrast" class="levels">
            <div class="level active"></div>
            <div :class="{active: contrastStep >= 1}" class="level"></div>
            <div :class="{active: contrastStep >= 2}" class="level"></div>
            <div :class="{active: contrastStep >= 3}" class="level"></div>
          </div>
        </div>

        <div class="tool" @click="changeDictation()">
          <div class="tool-title">
            <img :alt="$t('headphones')" src="@/assets/icons/headphones.svg">
            {{ $t('screenReader3', {dictationAudioStep: dictationAudioStep}) }}
          </div>
          <div class="levels">
            <div :class="{active: dictationAudioStep >= 1}" class="level"></div>
            <div :class="{active: dictationAudioStep >= 2}" class="level"></div>
            <div :class="{active: dictationAudioStep >= 3}" class="level"></div>
          </div>
        </div>

        <div class="tool" @click="increaseFontSize()">
          <div class="tool-title">
            <img alt="format_shapes" src="@/assets/icons/format_shapes.svg">
            {{ $t('fontSize6', {expr: fontSizeIncreaseStep + 1}) }}
          </div>
          <div class="levels">
            <div class="level active"></div>
            <div :class="{active: fontSizeIncreaseStep >= 1}" class="level"></div>
            <div :class="{active: fontSizeIncreaseStep >= 2}" class="level"></div>
            <div :class="{active: fontSizeIncreaseStep >= 3}" class="level "></div>
            <div :class="{active: fontSizeIncreaseStep >= 4}" class="level "></div>
            <div :class="{active: fontSizeIncreaseStep >= 5}" class="level "></div>
          </div>
        </div>

        <div class="tool" @click="changeDyslexicFont()">
          <div class="tool-title">
            <img :alt="$t('dyslexia')" src="@/assets/icons/dyslexia.svg">
            {{ $t('dyslexiaFriendly3', {expr: dyslexiaStep + 1}) }}
          </div>
          <div class="levels">
            <div class="level active"></div>
            <div :class="{active: dyslexiaStep >= 1}" class="level"></div>
            <div :class="{active: dyslexiaStep >= 2}" class="level"></div>
          </div>
        </div>

        <div class="tool" @click="changeFontType()">
          <div class="tool-title">
            <img alt="match_word" src="@/assets/icons/match_word.svg">
            {{ $t('fontType6', {expr: fontTypeStep + 1}) }}
          </div>
          <div class="levels">
            <div class="level active"></div>
            <div :class="{active: fontTypeStep >= 1}" class="level"></div>
            <div :class="{active: fontTypeStep >= 2}" class="level"></div>
            <div :class="{active: fontTypeStep >= 3}" class="level"></div>
            <div :class="{active: fontTypeStep >= 4}" class="level"></div>
            <div :class="{active: fontTypeStep >= 5}" class="level"></div>
          </div>
        </div>

        <div class="tool" @click="changeSaturation()">
          <div class="tool-title">
            <img :alt="$t('saturation')" src="@/assets/icons/saturation.svg">
            {{ $t('saturation4', {expr: this.saturationStep + 1}) }}
          </div>
          <div class="levels">
            <div class="level active"></div>
            <div :class="{active: saturationStep >= 1}" class="level"></div>
            <div :class="{active: saturationStep >= 2}" class="level"></div>
            <div :class="{active: saturationStep >= 3}" class="level"></div>
          </div>
        </div>

        <div class="tool" @click="changeTextSpacing()">
          <div class="tool-title">
            <img alt="letter_spacing" src="@/assets/icons/letter_spacing.svg">
            {{ $t('textSpacing4', {expr: textSpacingStep + 1}) }}
          </div>
          <div class="levels">
            <div class="level active"></div>
            <div :class="{active: textSpacingStep >= 1}" class="level"></div>
            <div :class="{active: textSpacingStep >= 2}" class="level"></div>
            <div :class="{active: textSpacingStep >= 3}" class="level"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@/components/Slider.vue";

export default {
  name: 'AccessibilityTools',
  components: {Slider},
  data() {
    return {
      contrastStep: 0,
      fontSizeIncreaseStep: 0,
      saturationStep: 0,
      saturationValue: '',
      textSpacingStep: 0,
      dyslexiaStep: 0,
      dyslexiaFonts: [
        'Poppins',
        'Dyslexic',
        'Arial'
      ],
      fontTypeStep: 0,
      fontTypeFonts: [
        'Poppins',
        'Calibri',
        'Times New Roman',
        'Consolas',
        'Ebrima',
        'Verdana'
      ],
      dictationAudioStep: 0,
      isDictationEnabled: false,
      utterance: null,
      isOversizeWidget: false
    }
  },
  props: {
    showAccessibilityTools: false
  },
  methods: {
    closeTools() {
      this.$emit('closeAccessibilityTools')
    },

    contrastToDark(elements) {
      Array.from(elements).forEach(el => {
        if (!el.classList.contains('accessibility-tools')) {
          el.style.backgroundColor = 'rgb(0, 0, 0)';
          el.style.color = 'rgb(80, 208, 160)';
          el.style.borderColor = 'rgb(255, 255, 255)';
          if (el.children.length) {
            this.contrastToDark(el.children);
          }
        }
      });
    },

    contrastToLight(elements) {
      Array.from(elements).forEach(el => {
        if (!el.classList.contains('accessibility-tools')) {
          el.style.backgroundColor = 'rgb(255, 255, 255)';
          el.style.color = 'rgb(0, 0, 0)';
          el.style.borderColor = 'rgb(0, 0, 0)';
          if (el.children.length) {
            this.contrastToLight(el.children);
          }
        }

        if (el.tagName === 'IMG') {
          el.style.backgroundColor = 'rgb(0,0,0)';
        }
      });
    },

    resetContrast(elements) {
      Array.from(elements).forEach(el => {
        if (!el.classList.contains('accessibility-tools')) {
          el.style.backgroundColor = '';
          el.style.color = '';
          el.style.borderColor = '';
          if (el.children.length) {
            this.resetContrast(el.children);
          }
        }
      });
    },

    changeContrast(onInit = false) {
      if (!onInit) {
        this.contrastStep++;
        localStorage.setItem('contrastStep', this.contrastStep);
      }

      const body = document.body;

      if (this.contrastStep === 1) {
        body.style.filter = 'invert(100%)';
        if (Object.keys(this.$refs).length) {
          this.$refs["ac-tools"].style.filter = 'invert(1)'
        }
        this.saturationStep = 0;
        return;
      }

      const elements = document.querySelector('#app').children;

      if (this.contrastStep === 2) {
        body.style.filter = '';
        if (Object.keys(this.$refs).length) {
          this.$refs["ac-tools"].style.filter = ''
        }
        this.contrastToDark(elements);
        return;
      }

      if (this.contrastStep === 3) {
        this.contrastToLight(elements);
        return;
      }

      if (this.contrastStep === 4) {
        this.resetContrast(elements);
        this.contrastStep = 0;
        localStorage.setItem('contrastStep', this.contrastStep);
      }

    },

    increaseFontSize(onInit = false) {
      if (!onInit) {
        this.fontSizeIncreaseStep++;
      }

      const root = document.querySelector(':root');

      if (this.fontSizeIncreaseStep < 6) {
        const newFontSieValue = 16 + this.fontSizeIncreaseStep * 2;
        root.style.setProperty('--main-font-size', `${newFontSieValue}px`)
      }

      if (this.fontSizeIncreaseStep === 6) {
        this.fontSizeIncreaseStep = 0;
        root.style.setProperty('--main-font-size', '16px')
      }

      localStorage.setItem('fontSizeIncreaseStep', this.fontSizeIncreaseStep);
    },

    changeSaturation(onInit = false) {
      if (!onInit) {
        this.saturationStep++;
      }

      const body = document.body;
      const currentBodyFilter = body.style.getPropertyValue('filter')?.replace(`${this.saturationValue}`, '');

      if (this.saturationStep < 4) {
        const saturateValue = this.saturationStep === 1 ? 0.5 : this.saturationStep === 2 ? 3 : 0;
        this.saturationValue = `saturate(${saturateValue})`

        body.style.filter = `${this.saturationValue} ${currentBodyFilter}`;
      }

      if (this.saturationStep === 4) {
        this.saturationStep = 0;
        body.style.filter = '';
      }

      localStorage.setItem('saturationStep', this.saturationStep);
    },

    changeTextSpacing(onInit = false) {
      if (!onInit) {
        this.textSpacingStep++;
      }

      const elements = document.querySelector('#app').children;

      if (this.textSpacingStep < 4) {
        this.increaseTextSpacing(elements, this.textSpacingStep);
      }

      if (this.textSpacingStep === 4) {
        this.textSpacingStep = 0;
        this.resetTextSpacing(elements);
      }

      localStorage.setItem('textSpacingStep', this.textSpacingStep);
    },

    increaseTextSpacing(elements, step) {
      Array.from(elements).forEach(el => {
        if (!el.classList.contains('accessibility-tools')) {
          if (el.innerText.trim().length) {
            if (step > 1) {
              el.classList.remove(`text-spacing-${step - 1}`);
            }

            el.classList.add(`text-spacing-${step}`);
          }

          if (el.children.length) {
            this.increaseTextSpacing(el.children, step)
          }
        }
      })
    },

    resetTextSpacing(elements) {
      Array.from(elements).forEach(el => {
        el.classList.remove('text-spacing-3');

        if (el.children.length) {
          this.resetTextSpacing(el.children);
        }
      });
    },

    changeDyslexicFont(onInit = false) {
      if (!onInit) {
        this.dyslexiaStep++;
      }

      const root = document.querySelector(':root');

      if (this.dyslexiaStep < 3) {
        const font = this.dyslexiaFonts[this.dyslexiaStep];
        root.style.setProperty('--font-family', font);
      }

      if (this.dyslexiaStep === 3) {
        this.dyslexiaStep = 0;
        const font = this.dyslexiaFonts[this.dyslexiaStep];
        root.style.setProperty('--font-family', font);
      }

      localStorage.setItem('dyslexiaStep', this.dyslexiaStep);
    },

    changeFontType(onInit = false) {
      if (!onInit) {
        this.fontTypeStep++;
      }

      const root = document.querySelector(':root');

      if (this.fontTypeStep < 6) {
        const font = this.fontTypeFonts[this.fontTypeStep];
        root.style.setProperty('--font-family', font);
      }

      if (this.fontTypeStep === 6) {
        this.fontTypeStep = 0;
        const font = this.fontTypeFonts[this.fontTypeStep];
        root.style.setProperty('--font-family', font);
      }

      localStorage.setItem('fontTypeStep', this.fontTypeStep);
    },

    changeDictation(onInit = false) {
      if (!onInit) {
        this.dictationAudioStep++;
      }
      if (this.dictationAudioStep === 1) {
        this.utterance.text = 'Audio dictation enabled';
        window.speechSynthesis.speak(this.utterance);
      }

      if (!this.isDictationEnabled) {
        this.isDictationEnabled = true;
        setTimeout(() => {
          window.addEventListener('click', (event) => {
            const text = event.target.innerText.trim()
            if (text.length > 0 && this.isDictationEnabled) {
              this.utterance.text = text;
              window.speechSynthesis.speak(this.utterance);
            }
          });
        }, 100);
      }

      if (this.dictationAudioStep === 2) {
        this.utterance.rate = 1.5
      }

      if (this.dictationAudioStep === 3) {
        this.utterance.rate = 0.5
      }

      if (this.dictationAudioStep === 4) {
        this.utterance.rate = 1;
        this.utterance.text = 'Audio dictation disabled';
        window.speechSynthesis.speak(this.utterance);
        this.isDictationEnabled = false;
        this.dictationAudioStep = 0
      }

      localStorage.setItem('dictationAudioStep', this.dictationAudioStep);
    },

    toggleOversizeWidget(value) {
      this.isOversizeWidget = value;
    },
  },

  mounted() {
    this.utterance = new SpeechSynthesisUtterance();
    const interval = setInterval(() => {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        this.utterance.voice = voices.find(x => x.name === 'Google UK English Female') || voices.find(x => x.name === 'Microsoft Libby Online (Natural) - English (United Kingdom)');
        clearInterval(interval);
      }
    }, 10)

    this.contrastStep = Number(localStorage.getItem('contrastStep')) || 0;
    if (this.contrastStep > 0) {
      this.changeContrast(true);
    }

    this.fontSizeIncreaseStep = Number(localStorage.getItem('fontSizeIncreaseStep')) || 0;
    if (this.fontSizeIncreaseStep > 0) {
      this.increaseFontSize(true);
    }

    this.saturationStep = Number(localStorage.getItem('saturationStep')) || 0;
    if (this.saturationStep > 0) {
      this.changeSaturation(true);
    }

    this.textSpacingStep = Number(localStorage.getItem('textSpacingStep')) || 0;
    if (this.textSpacingStep > 0) {
      this.changeTextSpacing(true);
    }

    this.dyslexiaStep = Number(localStorage.getItem('dyslexiaStep')) || 0;
    if (this.dyslexiaStep > 0) {
      this.changeDyslexicFont(true);
    }

    this.fontTypeStep = Number(localStorage.getItem('fontTypeStep')) || 0;
    if (this.fontTypeStep > 0) {
      this.changeFontType(true);
    }
  },

  watch: {
    $route() {
      this.changeContrast(true);
      this.changeTextSpacing(true);
    }
  }
}
</script>

<style lang="scss" scoped>
.oversize {
  width: 520px !important;
  font-size: calc(var(--main-font-size) + 5px) !important;

  .tool {
    height: 78px !important;
    font-size: calc(var(--main-font-size) + 5px)!important;
  }

  .level {
    height: 6px!important;
  }

  .tool-title {
    img {
      width: 32px!important;
      height: 32px!important;
    }
  }
}

.accessibility-tools {
  width: 355px;
  min-height: 200px;
  background-color: white;
  position: absolute;
  right: 0;
  top: 94px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  font-size: var(--main-font-size);
  font-weight: 500;
  box-shadow: -11px 10px 26px -1px rgba(0,0,0,0.75);

  @media (max-width: 1224px) {
    width: 100%;
  }

  .title {
    width: 100%;
    height: 52px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: #ffffff;

    .rep {
      color: rgba(146, 197, 235, 1);
      font-weight: 800;
    }

    @media (max-width: 1224px) {
      font-size: var(--main-font-size);
    }
  }

  .activation {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: 1224px) {
      padding: 16px;
    }
  }

  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    @media (max-width: 1224px) {
      font-size: var(--main-font-size);
      display: none;
    }

    .combination {
      padding: 4px 16px;
      border-radius: 24px;
      height: 30px;
      color: #ffffff;
      font-size: calc(var(--main-font-size) - 4px);
      background: #000;
      display: flex;
      align-items: center;
      @media (max-width: 1224px) {
        font-size: var(--main-font-size);
      }
    }
  }

  .tools {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .tool {
      width: 100%;
      min-height: 68px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 64px;
      padding: 16px 32px;
      font-size: var(--main-font-size);
      font-weight: 600;
      color: #000000;
      background: linear-gradient(88.66deg, #E6F2FF 0%, #F4F3FB 99.72%),
      linear-gradient(0deg, #ECEDF1, #ECEDF1);

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 1224px) {
        font-size: var(--main-font-size);
        height: 68px;
        gap: 8px;
        padding: 12px 32px;
      }

      .tool-title {
        display: flex;
        gap: 8px;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .levels {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;

        .level {
          width: 62px;
          height: 4px;
          border-radius: 16px;
          background: #000000;
          transition: .2s ease-in-out;
        }

        .active {
          background: rgba(146, 197, 235, 1);
        }
      }
    }
  }
}
</style>
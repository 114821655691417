<template>
  <div id="op-card" ref="block" class="card">
    <img class="card-img" :alt="card.title" :src="imageUrl">
    <div class="d-flex align-items-center ">

      <p class="text-bold title">
        {{ card.title }}
      </p>

      <img :alt="$t('right')" src="@/assets/icons/arrow_right_alt.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'testNewTooltips',
  props: {
    card: {
      type: Object,
      required: true
    },
    selectedLanguage: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_API_URL + this.card.icon;
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 384px;
  height: 163px;
  background-color: rgba(146, 197, 235, 1);
  border-radius: 8px;
  box-shadow: -8px 5px 0px 0px rgba(181, 176, 216, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: underline;

  .card-img {
    width: 64px;
    height: auto;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 60px solid white;
    border-left: 60px solid rgba(146, 197, 235, 1);
    width: 0;
  }

  @media (max-width: 1224px) {
    width: 100%;
    height: 66px;
    flex-direction: row;
    gap: 8px;

    &:before {
      border-top: 30px solid white;
      border-left: 30px solid rgba(146, 197, 235, 1);
    }

    .card-img {
      width: 32px;
      height: 32px;
    }

    .title {
      font-size: calc(var(--main-font-size) - 2px);;
    }
  }
}

@media (max-width: 400px) {
  .d-flex{
    flex: 0 0 220px;
    justify-content: space-between;
    .title{
      //flex: 0 0 160px;
    }

  }
}
</style>

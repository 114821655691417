<template>
  <div class="container">
    <span class="header">Top stories</span>
    <div v-if="loading" class="loading">Загрузка...</div>
    <div v-else class="stories-container">
      <div v-for="(story, index) in visibleStories" :key="story.id" class="story-block">
        <span class="date">{{ formatDate(story.createdAt) }}</span>
        <a :href="story.url" class="title" target="_blank">{{ story.title }}</a>
      </div>
      <button v-if="showMoreButton && isMobile" @click="showMore" class="btn-secondary">
        Show More
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topStories',
  props: ['stories', 'loading'],
  data() {
    return {
      visibleStories: [],
      itemsToShow: 4,
      showMoreButton: false,
      isMobile: window.innerWidth <= 768
    };
  },
  watch: {
    stories: 'updateVisibleStories'
  },
  methods: {
    formatDate(dateString) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return isNaN(date) ? 'Invalid date' : date.toLocaleDateString('en-US', options);
    },
    showMore() {
      this.itemsToShow += 3;
      this.updateVisibleStories();
      this.showMoreButton = this.stories.length > this.itemsToShow && this.isMobile;
    },
    updateVisibleStories() {
      const sortedStories = [...this.stories].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (this.isMobile) {
        this.visibleStories = sortedStories.slice(0, this.itemsToShow);
      } else {
        this.visibleStories = sortedStories.slice(0, 8);
      }
      this.showMoreButton = this.stories.length > this.itemsToShow && this.isMobile;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      this.updateVisibleStories();
      this.showMoreButton = this.stories.length > this.itemsToShow && this.isMobile;
    }
  },
  mounted() {
    this.updateVisibleStories();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 16px!important;
  padding: 0!important;
  margin: 0!important;
  width: 100%!important;
}

.header {
  color: #024694;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 0.72px;
}

.loading {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}

.stories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem 1.5rem;
  @media (max-width: 768px) {
    gap: 0;
  }
}

.story-block {
  background-color: #F0F7FC;
  border-radius: 8px;
  padding: 16px;
  width: 48%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 0 0 calc(50% - 12px);
}

.date {
  color: #373737;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.title {
  color: #184892;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  text-decoration: none;
}

.title:hover {
  text-decoration: underline;
}

.btn-secondary {
  width: 60%;
  font-size: calc(var(--main-font-size) + 2px);
  font-weight: 700;
  padding: 16px 90px;
  gap: 10px;
  line-height: 16px;
  border-radius: 8px;
  border: 2px solid #034694;
  color: #034694;
  text-decoration: none;
  align-self: center;
  margin-top: 16px;
}

.btn-secondary:hover {
  border-color: #034694;
  background-color: #034694;
  color: #ffffff;
}

@media (max-width: 1224px) {
  .btn-secondary {
    width: 100%;
    font-size: calc(var(--main-font-size) + 2px);
    padding: 16px 40px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 16px;
  }

  .stories-container {
    flex-direction: column;
  }

  .story-block {
    width: 100%;
    margin: 8px 0;
  }
}
</style>

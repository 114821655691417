<template>
  <div class="container-events">
    <div class="banner">
      <div class="container">
        <div class="banner-content">
          <h1 class="text-default">
            <span>{{ $t('dontMiss') }}</span><br>
            {{ $t('ourEvents') }}
          </h1>
        </div>
      </div>
      <img src="@/assets/icons/slice-top.png" alt="" class="corner">
    </div>

    <div class="events-wrapper">
      <div class="container-event">
        <span class="container-title">
          {{ $t('eventsIn2024') }}
        </span>

        <span class="container-description">
          {{ $t('willGladToSee') }} <br>
          {{ $t('saveTheDate') }}
        </span>

        <div class="selection-date">
          <radio-btn :options="eventOptions" @change="updateSelectedMonthLabel"/>
        </div>

        <span class="date-description">
          {{ monthNames[selectedDate.getMonth()] + " " + selectedDate.getFullYear() }} Morning and Evening Sessions
        </span>

      </div>

      <div v-for="(badge, badgeIndex) in filteredBadges" :key="badgeIndex" class="accordion-container">
        <div class="badge-header" @click="toggleBadge(badgeIndex)">
          <h4 :class="{ 'opened': badge.opened }">{{ badge.title }}</h4>
          <p>{{ badge.description }}</p>
          <div :class="{ 'rotate': badge.opened }" class="header-icon">
            <img src="@/assets/icons/blue-arrow.svg" alt="arrow"/>
          </div>
        </div>

        <div v-show="badge.opened">
          <div v-for="(event, indexEvent) in badge.events" :key="indexEvent" class="item">
            <div class="title-group">
              <span class="title">{{ event.title }} <span class="type">{{ event.type }}</span></span>
              <div v-if="(!isMobile && (event.url !== ''))" @click="openEvent(event.url)" class="btn">
                Sign Up
              </div>
            </div>
            <p class="description" v-html="event.description"></p>
            <div class="session-group">
              <div v-if="(event.approximate === false)" class="group">
                <p class="session">Starts</p>
                <p class="date">{{ formatFullDate(event.dates[event.selectedDate]) }}</p>
              </div>
              <div v-if="(event.approximate === false)" class="all-date-group">
                <div
                    v-for="(date, dateIndex) in event.dates"
                    :key="dateIndex"
                    :class="['date', { selected: event.selectedDate === dateIndex }]"
                    @click="selectDate(event, dateIndex)"
                >
                  <p>{{ dateFormatter(date) }}</p>
                </div>
              </div>
              <div v-else class="all-date-group">
                <div
                    v-if="event.dates"
                    class="date"
                >
                  <p>{{ dateApproximateFormatter(event.dates[0]) }}</p>
                </div>
              </div>
            </div>
            <div class="leaflet-group">
              <div v-for="(leaf, indexLeaflet) in event.leaflet" :key="indexLeaflet" class="item-leaflet">
                <img :alt="$t('document')" class="document" src="@/assets/icons/document.svg">
                <div class="text">
                  <p>{{ leaf.title }}</p>
                </div>
                <img @click="showPoster(leaf.url)" :alt="$t('download')" class="download" src="@/assets/icons/download.svg">
              </div>
            </div>

            <div v-if="(isMobile && (event.url !== ''))" @click="openEvent(event.url)" class="btn">
              Sign Up
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {fetchBadges} from "@/main";
import languageService from "@/services/language";
import {EventBus} from "@/services/eventBus";
import RadioBtn from "@/components/shared/radioBtn.vue";
import BtnMain from "@/components/shared/btnMain.vue";

export default {
  name: "Events",
  components: {RadioBtn, BtnMain},
  data() {
    return {
      isModalVisible: false,
      selectedLanguage: 'en',
      banner: {},
      selectedDate: new Date(),
      eventOptions: this.generateEventOptions(),
      badges: [],
      filteredBadges: [],
      monthNames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      isMobile: false
    }
  },
  async mounted() {
    await this.initBanner();
    await this.loadBadges();
    this.filteredBadges = this.filterBadges();
    if (this.filteredBadges.length > 0) {
      this.filteredBadges[0].opened = true;
    }
    this.selectedLanguage = this.$i18n.locale;
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
    EventBus.$on('languageChangedHeader', (newLanguage) => {
      this.selectedLanguage = newLanguage;
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  methods: {
    dateFormatter(dateString) {
      let date = new Date(dateString.date)
      return date.toLocaleDateString('en-GB', {day: '2-digit', month: 'short'})
    },
    dateApproximateFormatter(dateString) {
      let date = new Date(dateString.date)
      return date.toLocaleDateString('en-GB', {month: 'long'})
    },
    formatFullDate(dateString) {
      let date = new Date(dateString.date)
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    },
    filterBadges() {
      // Получаем месяц выбранной даты
      const selectedMonth = this.selectedDate.getMonth();

      // Проходим по всем бейджам и фильтруем события по выбранному месяцу
      const filteredBadges = this.badges.map(badge => {
        const filteredEvents = badge.events.map(event => {
          // Фильтруем даты в событии по выбранному месяцу
          const filteredDates = event.dates.filter(dateObj => {
            const date = new Date(dateObj.date);
            return date.getMonth() === selectedMonth;
          });

          // Возвращаем событие только если есть подходящие даты
          return {
            ...event,
            dates: filteredDates
          };
        }).filter(event => event.dates.length > 0);

        // Возвращаем бейдж только если есть подходящие события
        return {
          ...badge,
          events: filteredEvents
        };
      }).filter(badge => badge.events.length > 0);

      if (filteredBadges.length > 0) {
        filteredBadges[0].opened = true;
      }

      return filteredBadges;
    },

    generateEventOptions() {
      const currentDate = new Date();
      const options = [];
      for (let i = 0; i < 4; i++) {
        //current month + i month
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
        options.push(date);
      }
      return options;
    },
    updateSelectedMonthLabel(selectedOption) {
      this.selectedDate = selectedOption;
      this.filteredBadges = this.filterBadges();
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

    formatGeneralDates(dates) {
      return dates.map(dateObj => {
        const date = new Date(dateObj.date);
        return date.toString() !== 'Invalid Date' ? date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short'
        }) : 'Invalid Date';
      });
    },
    formatSpecificDate(date) {
      const d = new Date(date);
      if (d.toString() !== 'Invalid Date') {
        const options = {day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', hour12: true};
        return d.toLocaleDateString('en-GB', options);
      }
      return 'Invalid Date';
    },
    openEvent(url) {
      window.open(url, '_blank');
    },
    selectDate(event, dateIndex) {
      event.selectedDate = dateIndex
    },
    async loadBadges() {
      try {
        const data = await fetchBadges();
        this.badges = data.map(badge => ({
          ...badge,
          opened: false,
          events: badge.events.map(event => ({
            ...event,
            selectedDate: 0,
          }))
        }));
      } catch (error) {
      }
    },
    async initBanner() {
      await axios.get(process.env.VUE_APP_API_URL + "/images/main").then((response) => {
        this.banner = response.data;
      });
    },
    changeLanguage() {
      languageService.changeLang(this.selectedLanguage, this.$i18n);
      EventBus.$emit('languageChangedHome', this.selectedLanguage);
    },
    getBanner() {
      if (this.banner.url) {
        return {
          backgroundImage: 'url(' + process.env.VUE_APP_API_URL + this.banner.url + ')'
        }
      } else {
        return {}
      }
    },
    toggleBadge(index) {
      if (this.filteredBadges[index].opened === true) {
        this.filteredBadges[index].opened = false;
      } else {
        this.filteredBadges[index].opened = true;
      }

      // this.$set(this.badges, index, {
      //   ...this.badges[index],
      //   opened: !this.badges[index].opened
      // });
    },
    filteredEvents(events) {
      if (!this.selectedDate) {
        return events;
      }
      const month = this.eventOptions.find(option => option.id === this.selectedDate).label;
      return events.filter(event => event.date.includes(month));
    },
    showPoster(url) {
      window.open(process.env.VUE_APP_API_URL + url, '_blank').focus();
    },
  },
  watch: {
    selectedLanguage(newLang, oldLang) {
      if (newLang !== oldLang) {
        this.changeLanguage(newLang);
      }
    },
    selectedDate() {
      this.filteredBadges = this.filterBadges()
    }
  },
}
</script>

<style lang="scss" scoped>
.container-events {
  display: flex;
  flex-direction: column;
  text-align: start !important;

  .banner {
    height: 482px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .corner {
      width: 128px;
      position: absolute;
      top: -2px;
      @media (max-width: 1224px) {
        width: 70px;
        right: 0;
        transform: rotate(90deg);
      }
    }

    @media (max-width: 1224px) {
      background: #dee1da;
      height: 200px;
      background-image: none!important;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px) !important;
      }
    }

    .text-default {
      font-size: calc(var(--main-font-size) + 32px);
      color: #034694;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 1.44px;
      direction: ltr;

      span {
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 0 1rem;
  }
}

.events-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 120px auto;
  width: 60%;

}

.accordion-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.badge-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F0F7FC;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 1rem;

}

.badge-header h4 {
  margin: 0;
  font-size: 24px;
  color: #000000;
}

.badge-header h4.opened {
  color: #024694;
}

.badge-header p {
  margin: 0;
  font-size: 16px;
  color: #6A88B8;
}

.header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-icon.rotate {
  transform: rotate(90deg);
}


.item {
  padding: 1rem;
  border-radius: 8px;
  background: #FFF;
}

.line {
  width: 100%;
  position: relative;
  border: 1px solid #02469429;
  box-sizing: border-box;
  height: 1px;
  margin: 32px 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: none;
  border-radius: 8px;
  background: #184892;
  height: 32px;
  min-width: 125px;
  border: none;
  outline: none;
  padding: 0.5rem 2rem;
  color: #FFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.096px;
  cursor: pointer;
}

.date {
  cursor: pointer;
}

.container-event {
  padding: 2rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .container-title {
    color: #024694;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.96px;
  }

  .container-description {
    color: #034694;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    letter-spacing: 0.72px;
  }

  .selection-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .date-title {
      color: #000;
      text-align: center;
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */

      &.selected {
        color: #184892;
      }
    }
  }
}

.date-description {
  color: #000;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
}

.container-events {
  border-radius: 8px;
  background: #FFF;

  .btn {
    margin-right: auto;
    cursor: pointer;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;
    position: relative;

    .title-group {
      display: flex;
      justify-content: space-between;

      .title {
        color: #000;
        font-family: 'Arial', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin: 0;
      }

      .type {
        color: #6A88B8;
        font-family: Arial, sans serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
      }

      .btn {
        margin-right: 0;
        cursor: pointer;
      }
    }

    .description {
      color: #000;
      font-family: 'Arial', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 0;
    }

    .session-group {
      display: flex;
      justify-content: space-between;

      .group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        min-width: 10rem;

        .session {
          color: #6A88B8;
          font-family: 'Arial', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          margin: 0;
        }

        .date {
          @extend .session;
          color: #000;
          font-weight: 400;
          cursor: pointer;

          &.selected {
            background-color: #184892;
            color: #FFF;
          }
        }
      }

      .all-date-group {
        display: flex;
        gap: 1rem;
        margin-left: auto;
        flex-wrap: wrap;

        .date {
          border-radius: 8px;
          background: #DFF1FF;
          padding: 6px 10px;
          height: fit-content;
          margin: auto 0;

          p {
            color: #000;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin: 0;
          }
        }
      }
    }

    .leaflet-group {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .item-leaflet {
        display: flex;
        gap: 1rem;

        .document {
          width: 2rem;
          height: 2rem;
          margin: auto 0;
        }

        .download {
          width: 1.5rem;
          height: 1.5rem;
          margin: auto 0;
          cursor: pointer;
        }

        .text {
          color: #000;
          font-family: 'Arial', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin: auto 0;
        }
      }
    }
  }

  .item::before {
    content: '';
    position: absolute;
    bottom: -2rem;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(2, 70, 148, 0.00) 0%, rgba(2, 70, 148, 0.16) 42.5%, rgba(2, 70, 148, 0.00) 100%);
  }


  .item:last-child::before {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .container-events {

    .banner {
      height: 200px;
      text-align: center;

      .corner {
        width: 50px;
        right: 0;
        transform: rotate(90deg);
      }

      .text-default {
        font-size: 1.5rem;
      }
    }

    .events-wrapper {
      width: 100%;
      margin: 0;
    }

    .container-event {
      padding: 1rem;
      text-align: center;

      .container-title {
        font-size: 1.5rem;
      }

      .container-description {
        font-size: 1rem;
      }

      .selection-date {
        gap: 1rem;
      }

      .date-description {
        font-size: 18px;
      }
    }

    .accordion-container {
      padding: 0 1rem;

      .badge-header {
        padding: 0.5rem 1rem;
        margin-bottom: 3rem;

        h4 {
          font-size: 1rem;
        }

        p {
          font-size: 0.875rem;
        }

      }

      .item {
        padding: 0.5rem;

        .title-group {
          flex-direction: column;
          gap: 1rem;

          .title {
            font-size: 1rem;
            text-align: center;
          }

          .btn {
            padding: 0.25rem 1rem;
            font-size: 0.875rem;
          }
        }

        .description {
          font-size: 0.875rem;
          text-align: center;

        }

        .session-group {
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;

          .group {
            align-items: flex-start;


          }

          .all-date-group {
            flex-wrap: wrap;
            gap: 0.5rem;

            .date {
              font-size: 0.75rem;
            }
          }
        }

        .leaflet-group {
          flex-direction: column;
          align-items: center;

          .item-leaflet {

            .document {
              width: 1rem;
              height: 1rem;
            }

            .text {
              font-size: 0.875rem;
            }
          }
        }

        .btn {
          width: 100%;
        }
      }
    }
  }
}

</style>

<template>
  <div id="google_translate_element"></div>
</template>


<script>
export default {
  name: 'GoogleTranslate',
  methods: {
    googleTranslateElementInit() {
      new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }
  },
  mounted() {
    let googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';
    googleTranslateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.head.appendChild(googleTranslateScript);

    window.googleTranslateElementInit = this.googleTranslateElementInit;
  }
};
</script>
<template>
  <div class="radio-group">
    <label v-for="option in options" :key="option.id" :class="['container-radio', { 'selected': selectedOption === option }]">
      {{ monthNames[option.getMonth()].substring(0, 3) + " " + option.getFullYear() }}
      <input :value="option" type="radio" v-model="selectedOption" name="radioGroup">
      <span class="checkmark"></span>
    </label>
  </div>

</template>

<script>
export default {
  name: "radioBtn",
  props: ['options'],
  data() {
    return {
      selectedOption: this.options[0],
      monthNames : [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ]
    };
  },
  watch:{
    selectedOption(){
      this.change()
    }
  },
  methods: {
    change() {
      this.$emit('change', this.selectedOption)
    }
  },
}
</script>

<style lang="scss" scoped>

.radio-group{
  display: flex;
  gap: 2rem;
width: fit-content;
}
.container-radio {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 48px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  max-width: 4rem;
  text-align: center;
  color: #000;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.container-radio:hover{
  background: transparent;
}

.container-radio.selected{
  color: #184892;
  font-weight: 700;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  left: 50%;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 4px solid rgba(208, 234, 253, 1);
}

/* On mouse-over, add a grey background color */
/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background: transparent;
  border: 4px solid rgba(24, 72, 146, 1);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(24, 72, 146, 1);
}
</style>